.desktopView {
  margin-left: 12px;
  @media only screen and (max-width: 600px) {
    margin-top: -1em;
    text-align: end;
}
}

    
.BackButton {
  margin-right: 0.3rem;
  color: #1890ff;
  font-size: 2em;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}

// .shopprLogoPng {
//   width: 12em;
//   @media only screen and (max-width: 600px) {
//     width: 6em;
//   }
// }
