.hideInMobileTrackOrderButton {
  display: block;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}
.SocialIcon {
  display: block;
  @media only screen and (max-width: 600px) {
    display: none;
  }
}
.showInMobileSocialIcon {
  display: none;
  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
.showInMobileLogo {
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
}
.iconcart {
  width: 2rem;
  height: 2rem;
  color: blue;
}

.OnlineShp {
  width: 18em;
  height: 18em;
}
.yourshop {
  justify-content: center;
  background-color: #3066be;
}

.box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  border-style: groove;
  margin: auto;
  width: 99%;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
.box-container .box {
  flex: 1 1 15rem;
  position: relative;
  overflow: hidden;
}
.box-container .content {
  text-align: center;
  background: #fff;
  padding: 1rem;
}
.box-container .content h3 {
  font-size: 1.5rem;
  color: rgb(22, 105, 194);
}
.box-container .content p {
  font-size: 1rem;
  color: #666;
  padding: 1rem 0;
}

.footer-top {
  padding-top: 3em;
}

.FooterTitle {
  color: #ffffff;
  text-align-last: center;
}

.form-wrapper1 {
  width: 100%;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.input_box {
  width: 18%;
}

.emailupdate_button {
  width: 100%;
  color: black;
  background-color: #3066be;
  color: whitesmoke;
  padding: 0.8em;
  border-radius: 0em;
}

.textInput {
  width: 21%;
  @media only screen and (max-width: 600px) {
    width: 95%;
    margin-bottom: 10px;
  }
}

.txtInp {
  width: 100%;
  text-align: center;
  padding: 0.7em;
}

.heding {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Poppins";
  font-size: 1.125em;
  font-weight: 900;
  color: black;
  background-color: orange;
  padding: 1% 0% 0% 1%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    font-size: 1em;
    font-weight: 700;
  }
}
.heding {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Poppins";
  font-size: 1.125em;
  font-weight: 900;
  color: black;
  background-color: orange;
  padding: 2px 0 0 5px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    font-size: 1em;
    font-weight: 700;
  }
}
.heding2 {
  font-size: 1.125em;
  color: black;
  padding: 0 1%;
}
.hdingNavdrwr {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Poppins";
  font-size: 1.125em;
  font-weight: 900;
  &_a {
    color: white;
  }
  background-color: orange;
  padding: 1% 0% 0% 1%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    font-size: 1em;
    font-weight: 700;
  }
}
.hdingNavdrwr2 {
  font-size: 1.125em;
  color: white;
  padding: 0 1%;
}
.adHBtn {
  background-color: #3066be;
  margin-bottom: 5%;
}
.adhBtN {
  background-color: #3066be;
}
.WhtImg {
  text-align: center;
  margin-top: 30%;
}
.adBtN {
  background-color: #3066be;
}
.ImgMid {
  text-align: center;
  margin-top: 30%;
}
.FooterMain {
  width: 96%;
  //   height: 32vh;
  background-color: orange;
  //   border: 2px solid red;
  @media only screen and (max-width: 600px) {
    height: auto;
  }
}
.products {
  @media only screen and (max-width: 600px) {
    margin-top: 1em;
  }
}
.button1 {
  display: flex;
  align-items: baseline;
  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
}

.button2 {
  @media only screen and (max-width: 600px) {
    display: flex;

    justify-content: center;
  }
}

.shop {
  // justify-content: center;
  margin: 0% 0% 0% 34%;
  background-color: #3066be;
  @media only screen and (max-width: 600px) {
    margin: 0% 0% 0% 0%;
  }
}
.desktopGap {
  margin-top: 70px;
  @media only screen and (max-width: 600px) {
    margin-top: 0;
  }
}

.footer_main_box_icons {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  //   margin: 28px 0px 0px 10px;
  //   align-items: end;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.h3color {
  color: black;
}
.shopprLogoPng {
  width: 12rem;
  @media only screen and (max-width: 600px) {
    width: 7rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.75rem;
  }
}
.container {
  width: 65%;
  height: 100%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}
.FormContainer {
  margin-left: 50%;
  @media only screen and (max-width: 600px) {
    margin-left: 0;
  }
}
