@import url("https://fonts.googleapis.com/css?family=Poppins|Roboto");
@import "~antd/dist/antd.css";
@import "~video-react/dist/video-react.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
::-webkit-scrollbar {
  width: 0.3125em;
  height: 0.125em;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

