.MobileHiderSideMenu {
  display: block;
}
.showInMobileLogo {
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
  }
}

.draweropenIcon {
  display: none;
}

.logo {
  width:12em;
  .small-logo {
    display: none;
  }
  .big-logo {
    display: block;
    width:12em;
  }
}

.NavbarContainer {
  // margin-top: -17px;
  // margin-left: 12em;
  height: 2em;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 7px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
@media only screen and (max-width: 600px) {
  .logo .big-logo {
    display: none;
    width:12em;
  }
  .small-logo {
    display: block;
  }
  .NavbarContainer {
    display: none;
  }
  .MobileHiderSideMenu {
    display: none;
  }
  .draweropenIcon {
    color: black;
    font-size: 2em;
    margin-left: 0.5em;
    display: block;
  }
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#style-3::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: rgb(180, 178, 178);
}

#components-layout-demo-custom-trigger .logo {
  height: 40px;
  background: rgba(255, 255, 255, 0.5);
  margin: 16px;
}

.animateContainer {
  position: relative;
  width: 260px;
  height: 100px;
  padding: auto 60px;
  display: grid;
  grid-template-columns: repeat(3, 60px);
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.animateContainer .circleCell {
  width: 28px;
  height: 28px;
  background: white;
  border-radius: 50%;
}

.animateContainer .circleCell:nth-child(1) {
  animation: circleAnimation 2s cubic-bezier(0.38, 0.08, 0.25, 1.08) infinite -0.6s;
}

.animateContainer .circleCell:nth-child(2) {
  animation: circleAnimation 2s cubic-bezier(0.38, 0.08, 0.25, 1.08) infinite -0.3s;
}

.animateContainer .circleCell:nth-child(3) {
  animation: circleAnimation 2s cubic-bezier(0.38, 0.08, 0.25, 1.08) infinite;
}

@keyframes circleAnimation {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    background: orange;
    transform: scale(1.3);
  }
  100% {
    opacity: 0;
    transform: scale(0.4);
  }
}
