.randomScreen {
  width: 50%;
  overflow: hidden;

  .webImgStyle {
    width: 100%; 
    height: 100vh;
  }
}
.randomScreenMobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .randomScreen {
    display: none;
  }
  .randomScreenMobile {
    display: block;

    .mobileImgStyle {
      margin-top: 2em;
      width: 100%;
    }
  }
}
.header-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 96px;
  /* identical to box height */

  /* display: flex;
    align-items: center; */
  /* text-align: right; */
  /* margin-left: 175px; */
  color: #000000;
}

.selling-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 39px;
  margin-left: 175px;
  color: #000000;
}

.continue-button {
  left: 1447.78px;
}

.iconset{
  display: flex;
  flex-direction:row;
  // width: 103%;
}